import React from "react";
import '../../css/search.css'


export default function HeaderLogo(props){
    let brandName = props.brandName;
    console.log(brandName);

    return(
        <div className="logo-container">
            <img className="logo-header" src="./zago.png" alt={brandName}/>           
        </div>
        )
}