import React from "react";
import HeaderLogo from "./HeaderLogo";
import SearchBar from "./SearchBar";
import ModalScanner from "../scanner/ModalScanner";
import { useMediaQuery } from "react-responsive";
import "../../css/search.css";
import { useAuth0 } from "@auth0/auth0-react";


export default function HeaderSearch(props) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 640px)" });
	const { logout } =
	useAuth0();

	return (
		<div className="header">
			<div className="logo-button-user">
				<div></div>
				<HeaderLogo brandName={props.image} />
				<button className="back-button">
				<i className="fa fa-power-off fa-lg" alt="logout" onClick={() => logout()}></i>
				</button>
			</div>
			<SearchBar
				query={props.query}
				handleInputChange={props.handleInputChange}
			/>
			<div>
				{isTabletOrMobile && (
					<ModalScanner
						scannerResult={props.scannerResult}
						onScanBarcode={props.onScanBarcode}
					/>
				)}
			</div>
		</div>
	);
}
